import request from './index'

// 13. 处理对公提现申请
export function dealteamdraw (data) {
  return request({
    url: '/api/user/balance/dealteamdraw',
    method: 'post',
    data
  })
}

// 15 获取对公提现记录
export function teamerDrawlog () {
  return request({
    url: '/api/user/balance/teamerdrawlog',
    method: 'get'
  })
}
